/*
This doc maintain list name of all feature flags defined in split.io
Naming of feature in split should follow the structure `back_{teamName}_{featureName} eg back_platform_comm_title_color
where back is backoffice, platform is a team, comm_title_color is feature flag name`
*/

const features = {
  COMM_TITLE_COLOR: 'back_platform_comm_title_color',
  PRICING_VOUCHER_PRODUCT_DETAILS: 'fe_pricing_voucher_product_details',
  OFFER_DIGITAL_PAYMENT: 'bo_dp_digital_payment',
  DISCOVERY_SEARCH_OVERRIDE: 'bo_discovery_search_override',
  SKU_OFFER_BULK_OFFER_UPLOAD: 'bo_sku_offer_bulk_upload',
  ACTION_TRAIL_CUSTOM_HEADER: 'back_se_action_trail_custom_header',
  DISCOVERY_DISABLE_BANNER_LINK_ENABLED: 'bo_dis_disable_banner_link_enabled',
  PRE_ORDER: 'back_gsr_pre_order',
  DISCOVERY_PRODUCT_COLLECTION_WITH_BANNER_ENABLED:
    'bo_dis_product_collection_with_banner_enabled',
  MEDIA_LINEITEM_MULTIDATES_MESSAGE:
    'back_martech_campaignlineitem_multidates_message',
  MEDIA_CAMPAIGN_BULKOPERATION: 'back_martech_campaign_bulk_operation',
  BACK_GSR_WEBSITE_COLUMN_VIEW: 'back_gsr_website_column_view',
  PRDOUCT_METADATA_MIGRATION_ENABLED:
    'bo_dis_product_metadata_migration_enabled',
  LOYALTY_MEMBERSHIP_TAB: 'back_loyalty_membership_tabs',
  SEARCH_SERVICE_FLAG: 'back_gsr_search_service_flag',
  ACTION_TRAIL_CSV_EXPORT: 'back_se_action_trail_csv_export',
  SNG_ACTION: 'back_se_sng_action',
  FILTER_BY_STORE_IDS: 'back_fulfilment_filter_by_store_ids',
  BULK_ENABLING_SNG_STORE: 'back_se_bulk_enabling_sng_store',
  DELIVERY_PREFERENCE_FFS: 'fe_delivery_preference_ffs',
  DELIVERY_PREFERENCE_PFC: 'fe_delivery_preference_pfc',
  BLOCK_STORE_RULE_CUD: 'back_fulfilment_block_store_rule_create_update_delete',
  ZONE_PREFIX_LABEL: 'ff_zone_package_label_config_json',
  CLONE_DESIGNATION: 'back_se_clone_designation',
  DESIGNATION_ROLE: 'back_se_designation_role',
  STORE_KIND_FIELD: 'back_se_storekind_select_field',
  STORE_EDIT_CONFIRMATION_MODAL: 'back_se_store_edit_confirmation_modal',
  HIDE_SEARCH_AND_BROWSE: 'back_se_hide_search_n_browse',
  STORE_SNG_OPT_HOURS_FORM: 'back_se_store_sng_hours_form',
  PREORDER_STAFF: 'back_se_preorder_staff',
  STORE_CONFIGURATION: 'back_se_store_configuration',
  BATCH_UPLOAD_STATUS: 'back_dis_batch_status',
  BACK_DIS_CAMPAIGN_LABEL: 'back_dis_campaign_label',
  LOYALTY_CUSTOMER_SUBMENU_TAB: 'back_loyalty_customer_tab',
  NEW_CUSTOMER_SEGMENT_SELECTION: 'back_offer_new_customer_segment_selection',
  LOYALTY_LINKPASS_FEATURES: 'back_loyalty_linkpass_feats',
  DISCOVERY_LAYOUT_EDIT: 'back_dis_layout_edit',
  ENABLE_STORE_GROUPINGS_FP_PAY_OFFERS:
    'back_offer_enable_store_groupings_fp_pay_offers',
  LOYALTY_LINK_SUBMENU: 'back_loyalty_link_submenu',
  OPERATIONS_DASHBOARD_LINK_TO_DELIVERY_ORDERS:
    'back_fulfilment_operations_dashboard_link_to_delivery_orders',
  APIGEE_MIGRATION: 'back_dx_apigee_migration',
  PREORDER_ESIGNATURE: 'back_se_preorder_esignature',
  LOYALTY_ADMIN_SERVICE_TOGGLE: 'back_loyalty_linkpass_admin_api',
  HIDE_ORDER_ROUTES: 'back_fulfilment_hide_order_routes', // fulfillment - non-config
  MOVE_COMPLETED_ORDER_TO_PACKED:
    'back_fulfilment_allow_move_completed_to_packed', // fulfillment - config
  MANAGER_ID_NULL_FALLBACK: 'back_se_manager_id_null_fallback',
  READ_ONLY_ACCESS_CONTROL: 'back_dis_read_only_access_control', // grocery
  BACK_DIS_WEBSITE_HOME_LAYOUT: 'back_dis_website_home_layout',
  BULK_GLOBAL_PRODUCT_COMPLEX_ATTRIBUTE:
    'back_dis_bulk_global_product_complex_Attributes',
  PREORDER_TRANSACTION_VALIDATION_BYPASS:
    'back_se_preorder_customer_validation_bypass',
  EMPLOYEE_STORE_SELECT_ALL: 'back_se_employee_stores_select_all',
  BACK_DIS_CACHE_CLEAR: 'back_dis_cache_clear',
  NEW_TC_PREORDER_OFFLINE: 'back_se_new_tc',
  DYNAMICS_BACKOFFICE_REVAMP_PHASE2: 'back_dynamics_revamp_phase2',
  MANAGE_PICKERS_SUBMENU: 'back_fulfilment_manage_pickers_submenu',
  DYNAMICS_BACKOFFICE_REVAMP_PHASE3: 'back_dynamics_revamp_phase3',
}

export default features
