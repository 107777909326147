import React from 'react'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'

const ManageProductScanner = (props) => {
  return (
    <AuthenticatedPage menu={props.menu}>
      <h1>{getMessage('omni-journey.manage.product-scanner')}</h1>
    </AuthenticatedPage>
  )
}

export default ManageProductScanner
